<template>
    <div class="menu-template">
        <div v-if="menuTypeArray.length === 0"  class="loading"></div>
        <div v-else class="menu-box" :key="'menuType'+key" v-for="(menuType,key) in menuTypeArray">
            <div class="menu-thead" @click="onMenuVisible(menuType.id)" :class="menuType.visible?'active':''">
                <span class="menu-name">{{ menuType.name }}</span>
                <i :class="menuType.visible? 'menu-up':'menu-down'"></i>
            </div>
            <div class="menu-search" v-if="menuType.name === $t('menu.brands') && menuType.visible">
                <div class="menu-input">
                    <input type="text" v-model="searchVal" @keyup="onSearch(menuType,key)" class="input" @focus="onFocus" @blur="onBlur" :placeholder="brandOrStorePlaceholder"/>
                </div>
                <a href="javascript:" class="icon"></a>
            </div>
            <div class="menu-body" :class="menuType.name === $t('menu.brands')?'menu-brands-scroll':''" v-if="menuType.name !== $t('menu.price') && menuType.name !== $t('menu.size')" v-show='menuType.visible'>
                <ul @scroll="carouselScroll">
                    <li :key="'menu'+index" v-for="(menu,index) in onMenuTypeList(menuType)" :style="menu.isParent !== 1 && !isPhone()?'padding:10px':''">
                        <div class="label">
                            <i class="sel-icon" v-if="menu.isParent !== 1"  @click="onSelectType(menu,menuType)" :class="menu.checkBox?'select':'selected'"></i>
                            <a href="javascript:" :style="menu.checkBox?'font-family: Montserrat-SemiBold,FZLT-SemiBold;':''"  @click="onSelectType(menu,menuType)">{{ menu.name }}</a>
                        </div>
                    </li>
                </ul>
                <div class="slider-page-n" v-if="menuType.name === $t('menu.brands')">
                    <span class="slider" :style="'top:'+slider+'%'"></span>
                </div>
            </div>
<!--            <div class="menu-retrieval" v-if="menuType.name === $t('menu.brands') && menuType.visible">-->
<!--                <a href="javascript:" @click="onBrandList(menuType)">{{ $t('menu.retrieval') }}</a>-->
<!--            </div>-->
            <div class="menu-body" v-if="menuType.name === $t('menu.price')"  v-show='menuType.visible'>
                <div class="menu-slider"> <Slider @on-change="onPrice" v-model="prizeArray" show-tip="never" :step="1" range :min="0" :max="99999"></Slider></div>
                <div class="menu-slider-number">
                    <span class="slider-left">{{ onCurrencySymbol() }}{{ prizeArray[0] }}</span>
                    <span class="slider-right">{{ onCurrencySymbol() }}{{ prizeArray[1] }}+</span>
                </div>
            </div>
            <div class="menu-body" v-if="menuType.name === $t('menu.size')"  v-show='menuType.visible'>
                <div class="menu-size">
                    <div class="size-thead" @click="onSize">
                        <span class="thead-name">{{ selectSize.name }}</span>
                        <i :class="sizeBodyVisible?'thead-up':'thead-down'"></i>
                    </div>
                    <div class="size-body" v-if="sizeBodyVisible">
                        <ul>
                            <li>
                                <div class="label" :key="'size'+key" v-for="(itemFirst,key) in selectSize.chartList.slice(0,Math.ceil(selectSize.chartList.length/2))">
                                    <i class="sel-icon" :class="itemFirst.checkBox?'select':'selected'" @click="onSelectedSize(itemFirst)"></i>
                                    <a href="javascript:" :style="itemFirst.checkBox?'font-family: Montserrat-SemiBold,FZLT-SemiBold;':''"  @click="onSelectedSize(itemFirst)">{{ itemFirst.value }}</a>
                                </div>
                            </li>
                            <li>
                                <div class="label" :key="'sizeType'+key" v-for="(itemLast,key) in selectSize.chartList.slice(Math.ceil(selectSize.chartList.length/2),selectSize.chartList.length)">
                                    <i class="sel-icon" :class="itemLast.checkBox?'select':'selected'" @click="onSelectedSize(itemLast)"></i>
                                    <a href="javascript:"  @click="onSelectedSize(itemLast)">{{ itemLast.value }}</a>
                                </div>
                                <div v-if="!isPhone()" class="size-more">
                                    <a href="javascript:" @click="onSizeGuideCategoryIdList">{{ $t('menu.sizeGuide') }}</a>
                                    <i class="thead-up"></i>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="size-item" v-if="sizeChartVisible">
                        <ul>
                            <li
                                :key="'sizeChart'+key"
                                v-for="(sizeItem,key) in sizeArray"
                                :style="(key+1) === sizeArray.length?'margin-bottom:1.16rem':''"
                                @click="onSelectSize(sizeItem)">
                                    <a href="javascript:" v-show="sizeItem.id !== selectSize.id"  @click="onSelectSize(sizeItem)">{{ sizeItem.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="menu-box" :key="'attribute'+key" v-for="(attribute,key) in attributeArray">-->
<!--            <div class="menu-thead" @click="showToggle(attribute)">-->
<!--                <span class="menu-name">{{ attribute.name }}</span>-->
<!--                <i :class="attribute.isSubshow? 'menu-up':'menu-down'"></i>-->
<!--            </div>-->
<!--            <div class="menu-body" v-show='attribute.isSubshow'>-->
<!--                <ul>-->
<!--                    <li :key="'list'+index" v-for="(list,index) in attribute.attrList">-->
<!--                        <div class="label" @click="onSelectAttribute(list)">-->
<!--                            <i class="sel-icon" :class="list.checkBox?'select':'selected'"></i>-->
<!--                            <a href="javascript:">{{ list.name }}</a>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
<!--        </div>-->
      <!--s: Size guide-->
      <div class="layout-size-guide" v-if="sizeGuideVisible">
        <div class="low-lining" @click="onClose"></div>
        <div class="guide-body">
          <a href="javascript:" @click="onClose" class="close"></a>
          <div class="guide-size-list">
            <h1 class="name" v-if="sizeGuideList">{{ sizeGuideList.name }}</h1>
            <div class="list-body">
              <SizeGuide v-if="sizeGuideList && sizeGuideList.nameList" :data="sizeGuideList.nameList"/>
            </div>
            <div class="guide-text">
              <p v-html="$t('details.sizeGuideText')"></p>
            </div>
          </div>
        </div>
      </div>
      <!--e: Size guide-->
    </div>
</template>
<script>
import ProductMenu from './productMenu'
export default ProductMenu
</script>
<style lang="less">
@import "productMenu";
</style>
