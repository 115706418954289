/**
 * 商品菜单
 * @type {{data(): {}}}
 */
import Vue from "vue";
import { Slider } from "iview";
Vue.component("Slider", Slider);
import { getMenuListService } from "@/service/system-service";
import { mapMutations, mapState } from "vuex";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import SizeGuide from "@/components/SizeGuide/sizeGuide.vue";
import { getSizeGuideCategoryIdListService } from "@/service/goods-remind-service";
import { isPhone } from "@/utils/tools";
const ProductMenu = {
  name: "ProductMenu",
  components: {
    SizeGuide
  },
  props: {
    /**
     * 判断是否是品牌
     */
    brand: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      language: (state) => state.language.code, //获取状态语言
      currencyCode: (state) => state.system.currencyCode, //获取状态管理币种代码
      menuId: (state) => state.product.menuId, //获取状态管理菜单id
      menuType: (state) => state.product.menuType, //获取状态管理菜单分类
      source: (state) => state.product.source, //获取状态管理来源
      secondType: (state) => state.product.secondType, //获取状态管理二级分类
      tertiaryType: (state) => state.product.tertiaryType, //获取状态管理三级级分类
      levelType: (state) => state.product.levelType, //获取状态管理四级分类
      isNew: (state) => state.product.isNew, //获取状态管理新款
      isSale: (state) => state.product.isSale, //获取状态管理折扣
      isOld: (state) => state.product.isOld, //获取状态管理古着
      label: (state) => state.product.label, //获取状态管理标签
      newSaleName: (state) => state.product.newSaleName //获取状态管理三级标签名称
    })
  },
  data() {
    return {
      isPhone: isPhone,
      menuTypeArray: [],
      slider: 0,
      scroll: 0,
      offsetHeight: 261,
      prizeArray: [0, 99999],
      attributeArray: [], //属性列表
      sizeArray: [], //尺码列表
      selectSize: [], //选中的尺码表
      sizeGuideList: [], //尺码表数据集
      sizeBodyVisible: true, //尺码内容可显示
      sizeChartVisible: false, //尺码表可显示
      sizeGuideVisible: false,
      searchVal: "", //搜索
      searchList: [], //存放搜索的总数据
      brandOrStorePlaceholder: this.$t("header.search")
    };
  },
  created() {
    /**
     * 订阅商品属性查询
     */
    $event.$on(EVENT_NAME.COMMODITY_ATTRIBUTE_QUERY, (obj) => {
      if (obj.name === this.$t("menu.category")) {
        for (let i in this.menuTypeArray) {
          for (let j in this.menuTypeArray[i].children) {
            if (obj.id === this.menuTypeArray[i].children[j].id) {
              this.menuTypeArray[i].children[j].checkBox = false;
            }
          }
        }
      }

      if (obj.name === this.$t("menu.brands")) {
        for (let i in this.menuTypeArray) {
          if (this.menuTypeArray[i].brandlist) {
            for (let j in this.menuTypeArray[i].brandlist) {
              if (obj.id === this.menuTypeArray[i].brandlist[j].id) {
                this.menuTypeArray[i].brandlist[j].checkBox = false;
                this.$forceUpdate();
              }
            }
          }
        }
      }

      if (
        obj.name !== this.$t("menu.brands") &&
        obj.name !== this.$t("menu.category") &&
        obj.name !== this.$t("menu.size") &&
        obj.name !== this.$t("menu.newIn") &&
        obj.name !== this.$t("menu.newSale")
      ) {
        for (let i in this.menuTypeArray) {
          if (this.menuTypeArray[i].attrList) {
            for (let j in this.menuTypeArray[i].attrList) {
              if (obj.id === this.menuTypeArray[i].attrList[j].id) {
                this.menuTypeArray[i].attrList[j].checkBox = false;
                this.$forceUpdate();
              }
            }
          }
        }
      }
      if (obj.name === this.$t("menu.size")) {
        for (let i in this.selectSize.chartList) {
          if (obj.id === this.selectSize.chartList[i].id) {
            this.selectSize.chartList[i].checkBox = false;
            this.$forceUpdate();
          }
        }
      }
      if (obj.name === this.$t("menu.newIn")) {
        for (let i in this.menuTypeArray) {
          for (let j in this.menuTypeArray[i].attrList) {
            if (obj.id === this.menuTypeArray[i].attrList[j].id) {
              this.menuTypeArray[i].attrList[j].checkBox = false;
            }
          }
        }
      }
    });
    if (this.brand) {
      /**
       * 订阅商品菜单事件
       */
      $event.$on(EVENT_NAME.PRODUCT_BRANDS_MENU_QUERY, () => {
        this.onMenuList().then();
      });
    } else {
      /**
       * 订阅商品菜单事件
       */
      $event.$on(EVENT_NAME.PRODUCT_MENU_QUERY, () => {
        this.onMenuList().then();
      });
    }
    this.onMenuList().then();
  },
  methods: {
    ...mapMutations("product", [
      "SET_LETTER",
      "SET_MENU_ID",
      "SET_LEVEL",
      "SET_LABEL",
      "SET_IS_PARENT",
      "SET_SECOND_TYPE",
      "SET_TERTIARY_TYPE",
      "SET_LEVEL_TYPE",
      "SET_MENU_ID",
      "SET_IS_NEW",
      "SET_IS_SALE",
      "SET_IS_NEW",
      "SET_IS_SALE",
      "SET_IS_OLD"
    ]),
    /**
     * 监听超卖滚动条
     */
    carouselScroll(e) {
      this.scroll = Math.ceil(e.srcElement.scrollTop);
      let height = this.searchList.length * 29 - this.offsetHeight;
      this.slider = ((this.scroll / height) * 261) / 2.9;
    },
    /**
     * 搜索
     * @param obj
     * @param key
     */
    onSearch(obj, key) {
      this.menuTypeArray[key].brandlist = this.onFilter();
    },
    onFocus() {
      this.brandOrStorePlaceholder = "";
    },
    onBlur() {
      this.brandOrStorePlaceholder = this.$t("header.search");
    },
    /**
     * 过滤列表相同名称
     * @param content
     * @returns {*[]}
     */
    onFilter() {
      return this.searchList.filter((value) => {
        //过滤数组元素
        return value.name.toUpperCase().includes(this.searchVal.toUpperCase()); //如果包含字符返回true
      });
    },
    /**
     * 跳转品牌列表页
     * @param obj
     */
    onBrandList(obj) {
      this.SET_MENU_ID(obj.categoryId);
      this.SET_LETTER("");
      this.$router.push("/brands");
    },
    /**
     * 关闭弹框
     */
    onClose() {
      this.sizeGuideVisible = false;
    },
    onSize() {
      if (this.sizeChartVisible) {
        this.sizeChartVisible = false;
        this.sizeBodyVisible = true;
      } else {
        this.sizeChartVisible = true;
        this.sizeBodyVisible = false;
      }
    },
    /**
     * 选中的表
     * @param obj
     */
    onSelectSize(obj) {
      this.selectSize = obj;
      this.sizeBodyVisible = true;
      this.sizeChartVisible = false;
    },
    /**
     * 货币符号
     */
    onCurrencySymbol() {
      switch (this.currencyCode) {
        case "gbp":
          return "£";
        case "usd":
          return "$";
        case "eur":
          return "€";
        case "cny":
          return "¥";
        default:
          return "¥";
      }
    },
    onMenuTypeList(obj) {
      if (obj.name === this.$t("menu.category")) {
        return obj.children;
      }
      if (obj.name === this.$t("menu.brands")) {
        return obj.brandlist;
      }
      if (obj.name === this.$t("menu.size")) {
        return obj.sizeList;
      }
      if (
        obj.name !== this.$t("menu.category") &&
        obj.name !== this.$t("menu.brands") &&
        obj.name !== this.$t("menu.size")
      ) {
        return obj.attrList;
      }
    },
    /**
     * 选择价格
     * @param price
     */
    onPrice(price) {
      this.$emit("on-price", price);
    },
    /**
     * 选择分类
     * @param obj
     */
    onSelectType(obj, menuType) {
      if (menuType.name === this.$t("menu.category")) {
        this.SET_LEVEL(obj.level);
        this.SET_IS_PARENT(obj.isParent);
        if (obj.isParent === 0) {
          obj.checkBox = !obj.checkBox;
          this.$emit("on-select-type", obj);
          // this.SET_LEVEL('')
        } else {
          if (obj.level === 3) {
            this.SET_TERTIARY_TYPE({
              categoryId: obj.categoryId,
              name: obj.name
            });
          }
          if (obj.level === 4) {
            this.SET_LEVEL_TYPE({
              categoryId: obj.categoryId,
              name: obj.name
            });
          }
          this.SET_MENU_ID(obj.categoryId);
          if (this.isSale == 1) {
            this.SET_LABEL(this.$t("menu.isSale") + " - " + obj.name);
          } else if (this.isNew == 1) {
            this.SET_LABEL(this.$t("menu.isNew") + " - " + obj.name);
          } else {
            this.SET_LABEL(obj.name);
          }
          this.onMenuList().then();
          $event.$emit(EVENT_NAME.PRODUCT_LIST_QUERY);
        }
      }

      if (menuType.name === this.$t("menu.brands")) {
        this.onSelectBrands(obj);
      }
      if (
        menuType.name !== this.$t("menu.category") &&
        menuType.name !== this.$t("menu.brands") &&
        menuType.name !== this.$t("menu.newIn") &&
        menuType.name !== this.$t("menu.size")
      ) {
        this.onSelectAttribute(obj, menuType);
      }
      if (menuType.name === this.$t("menu.newIn")) {
        this.onSelectNewIn(obj);
      }
      if (menuType.name === this.$t("menu.size")) {
        this.onSelectedSize(obj);
      }
    },
    /**
     * 选择属性
     * @param id
     * @param obj
     */
    onSelectAttribute(obj) {
      obj.checkBox = !obj.checkBox;
      this.$forceUpdate();
      this.$emit("on-select-attribute", obj);
    },
    /**
     * 选择品牌
     * @param obj
     */
    onSelectBrands(obj) {
      obj.checkBox = !obj.checkBox;
      this.$forceUpdate();
      this.$emit("on-select-brands", obj);
    },
    /**
     * 选择尺码
     * @param obj
     */
    onSelectedSize(obj) {
      obj.checkBox = !obj.checkBox;
      this.$forceUpdate();
      let size = {
        name: this.selectSize.name,
        obj: obj
      };
      this.$emit("on-select-size", size);
    },
    /**
     * 选择新款折扣古着
     * @param obj
     */
    onSelectNewIn(obj) {
      obj.checkBox = !obj.checkBox;
      if (obj.name === this.$t("menu.isNew")) {
        if (obj.checkBox) {
          this.SET_IS_NEW(1);
        } else {
          this.SET_IS_NEW(0);
        }
      }
      if (obj.name === this.$t("menu.isSale")) {
        if (obj.checkBox) {
          this.SET_IS_SALE(1);
        } else {
          this.SET_IS_SALE(0);
        }
      }
      if (obj.name === this.$t("menu.isOld")) {
        if (obj.checkBox) {
          this.SET_IS_OLD(1);
        } else {
          this.SET_IS_OLD(0);
        }
      }
      this.$forceUpdate();
      this.$emit("on-select-new-in", obj);
    },
    /**
     * 展开
     * @param id
     */
    onMenuVisible(id) {
      for (let i in this.menuTypeArray) {
        if (id === this.menuTypeArray[i].id) {
          this.menuTypeArray[i].visible
            ? (this.menuTypeArray[i].visible = false)
            : (this.menuTypeArray[i].visible = true);
          // this.menuTypeArray[i].visible = true
        } else {
          this.menuTypeArray[i].visible = false;
        }
      }
    },
    /**
     * 根据分类id获取属性标签
     * @returns {Promise<void>}
     */
    async onMenuList() {
      try {
        let params = {
          id: this.menuId,
          type: this.isNew == 1 || this.isSale == 1 || this.isOld == 1 ? 1 : 0
        };
        const { code, data } = await getMenuListService(params);
        if (code === 1) {
          for (let i in data) {
            if (this.brand && data[i].name === this.$t("menu.brands")) {
              data.splice(i, 1);
            }
            if (data[i].name === this.$t("menu.brands")) {
              this.searchList = data[i].brandlist;
            }
            if (data[i].name === this.$t("menu.category")) {
              for (let j in data[i].children) {
                if (data[i].children[j].isParent === 0) {
                  if (this.menuId === data[i].children[j].id) {
                    data[i].children[j].checkBox = true;
                    let obj = {
                      name: data[i].children[j].name,
                      id: data[i].children[j].id
                    };
                    this.$emit("on-select-type", obj);
                  } else {
                    data[i].children[j].checkBox = false;
                  }
                }
              }
            }
            if (
              data[i].name === this.$t("menu.newIn") ||
              data[i].name === this.$t("menu.newSale")
            ) {
              for (let j in data[i].attrList) {
                if (data[i].attrList[j].isParent === 0) {
                  if (this.newSaleName === data[i].attrList[j].name) {
                    data[i].attrList[j].checkBox = true;
                    let obj = {
                      name: data[i].attrList[j].name,
                      id: data[i].attrList[j].id
                    };
                    this.$emit("on-select-new-in", obj);
                  } else {
                    data[i].attrList[j].checkBox = false;
                  }
                }
              }
            }
            if (data[i].name === this.$t("menu.size")) {
              this.sizeArray = data[i].sizeList;
              this.selectSize = data[i].sizeList[0];
            }
          }
          // data[0].visible = true
          this.menuTypeArray = data;
          this.onMenuVisible(this.menuTypeArray[0].id)
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 根据分类查询尺码对照表
     * @returns {Promise<void>}
     */
    async onSizeGuideCategoryIdList() {
      try {
        let params = {
          categoryId: this.menuId
        };
        const { code, data } = await getSizeGuideCategoryIdListService(params);
        if (code === 1) {
          this.sizeGuideList = data;
          if (data && data.length !== 0) {
            document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
            this.sizeGuideVisible = true;
          } else {
            alert("暂无尺码表，临时提示！");
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  /**
   * 销毁事件
   */
  destroyed() {
    if (this.brand) {
      $event.$off([
        EVENT_NAME.COMMODITY_ATTRIBUTE_QUERY,
        EVENT_NAME.PRODUCT_BRANDS_MENU_QUERY
      ]);
    } else {
      $event.$off([
        EVENT_NAME.PRODUCT_MENU_QUERY,
        EVENT_NAME.COMMODITY_ATTRIBUTE_QUERY
      ]);
    }
  }
};
export default ProductMenu;
